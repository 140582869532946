.projects {
  height: 250vh;
  height: 2700px;
  margin-top: 16vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
}
.projects .projects-main-title {
  font-family: "Lora";
  font-size: 1.25rem;
  align-self: flex-start;
  position: block;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2.5%;
  border: 1px solid black;
}
.projects .projects-main-title .title-highlight::before {
  content: "";
  position: absolute;
  height: 1.25rem;
  width: 4.5rem;
  margin-top: 0.875rem;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  z-index: 0;
}
.projects .projects-main-title .first::before {
  width: 6.25rem;
}
.projects .projects-main-title .dark::before {
  content: "";
  position: absolute;
  height: 1.25rem;
  width: 4.5rem;
  margin-top: 0.875rem;
  background-color: rgba(250, 250, 250, 0.15);
  border-radius: 0.75rem;
  z-index: 0;
}
.projects .projects-main-title .dark.first::before {
  width: 6.25rem;
}
.projects .projects-main-title.dark {
  border: 1px solid white;
}
.projects .project-card {
  width: 70%;
  height: 450px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
}
.projects .project-card .card-image-wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 46%;
  height: 100%;
  z-index: 2;
}
.projects .project-card .card-image-wrap .card-image {
  position: absolute;
  width: 100%;
}
.projects .project-card .card-image-wrap .blob {
  height: 100%;
  width: auto;
  filter: blur(8px);
}
.projects .project-card .card-image-wrap .odd-blob {
  right: 0;
  margin-right: -20%;
}
.projects .project-card .card-image-wrap .even-blob {
  left: 0;
  margin-left: -15%;
}
.projects .project-card .card-image-wrap .card-link {
  height: auto;
  width: 60%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.projects .project-card .card-image-wrap .card-link .example {
  width: 100%;
}
.projects .project-card .card-text {
  position: relative;
  width: 54%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  z-index: 2;
}
.projects .project-card .card-text .card-text-title {
  font-size: 1.25rem;
  padding: 2.5%;
  width: 100%;
  text-decoration: none;
  color: #39A450;
  font-weight: 400;
}
.projects .project-card .card-text .card-text-title.dark {
  color: #4390F9;
}
.projects .project-card .card-text .card-text-text {
  font-weight: 300;
  font-size: 1rem;
  padding: 2.5%;
}
.projects .project-card .card-text .card-text-tech {
  font-size: 0.875rem;
  padding: 1.25% 2.5% 1.25% 2.5%;
  opacity: 0.7;
  width: 100%;
}
.projects .project-card .card-text .card-text-tech.dark {
  opacity: 0.5;
}
.projects .project-card .card-text .odd-card {
  text-align: end;
}

@media only screen and (min-width: 0rem) and (max-width: 42rem) {
  .projects {
    height: 350vh;
    margin-top: 8vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .projects .projects-main-title {
    align-self: flex-start;
    position: block;
    display: flex;
    justify-content: center;
  }
  .projects .project-card {
    width: 88%;
    height: 20%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0vh;
  }
  .projects .project-card .card-image-wrap {
    position: relative;
    display: flex;
    align-items: end;
    width: 100%;
    height: 50%;
  }
  .projects .project-card .card-image-wrap .card-image {
    position: absolute;
    width: 100%;
    height: auto;
  }
  .projects .project-card .card-image-wrap .blob {
    height: 100%;
    width: auto;
  }
  .projects .project-card .card-image-wrap .odd-blob {
    margin-bottom: -35%;
    margin-right: 0;
    rotate: 90deg;
  }
  .projects .project-card .card-image-wrap .card-link {
    height: auto;
    width: 72%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .projects .project-card .card-image-wrap .card-link .example {
    width: 100%;
    height: auto;
    overflow: visible;
  }
  .projects .project-card .card-text {
    width: 100%;
    height: auto;
    align-content: start;
  }
  .projects .project-card .card-text .card-text-tech {
    font-size: 0.75rem;
  }
  .projects .project-card .card-text .odd-card {
    text-align: start;
  }
}

