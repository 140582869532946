.about {
    margin-top: 8vh;
    position: relative;
    height: 60rem;
    width: 88%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Source Sans Pro', sans-serif; 
    // border: 1px solid black;
    .about-col {
        height: 90%;
    }

    // Column for Blurb Text
    .about-blurb {
        // border: 1px solid white;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 40%;
        align-self: start;
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 100;
        // border: solid 0.8px black;
        .blurb-title {
            font-family: 'Lora';
            display: flex;
            align-items: center;
            justify-content: left;
            width: 70%;
            padding: 2.5%;
            font-size: 1.25rem;
            border: 1px solid black;
        }
        .blurb-text {
            font-family: 'Vazirmatn';
            font-weight: 200;
            display: flex;
            flex-direction: column; 
            align-items: center;
            justify-content: center;
            width: 70%;
            padding: 2.5%;
            margin-top: 7.5%;
            font-size: 1rem;
            border: 1px solid black;
        }
        .blurb-bubbleWrap {
            position: absolute;
            z-index: -1;
            filter: blur(124px);
            opacity: .5;
        }
        .dark {
            border: 1px solid white;
        }
    }
    // Column for Detail Technologies
    .about-detail {
        position: relative;
        align-self: end;
        align-content: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 60%;
        height: 80%;
        z-index: 0;
        .detail-title {
            font-family:  'Lora', 'Source Sans Pro';
            position: relative;
            display: flex;
            align-items:center;
            align-content: center;
            justify-content: center;
            width: 330px;
            width: 40%;
            height: 330px;
            .title-blob {
                position: absolute;
                z-index: 55;
                width: 100%;
            }
            .title-text {
                margin-left: -3%;
                z-index: 56;
                font-size: 2rem; 
                text-align: center;
                width: 80%;
            }
            .dark {
                color: black;
            }
        }
        .detail-techs {
            position: relative;
            width: 100%;
            max-width: 695px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            .tech-icon {
                width: 20%;
                height: auto;
                z-index: 2;
                text-align: center;
                .tech-icon-image {
                    width: 80%;
                }
                p {
                    margin-top: -12%;
                    font-size: .8rem;
                    font-family: 'Vazirmatn';
                    font-weight: 100;
                }
            }
        }
        .detail-blob {
            position: absolute;
            // height: auto;
            // width: 100%; 
            bottom: 0;
        }
    }

  }
  
  
  // 43rem, 62rem, 
  @media only screen and (min-width: 0rem) and (max-width: 42rem) {
    .about {
        margin-top: 6vh;
        height: 100rem;
        width: 100%;
        .about-col{
            width: 100%;
        }
        .about-blurb {
            align-content: center;
            height: 75%;
            width: 100%;
            .blurb-title, .blurb-text {
                width: 84%;
                padding: 1rem;
            }
        }
        .about-detail {
            align-self: start;
            align-content: start;
            align-items: start;
            height: 22%;
            width: 84%;
            .detail-title{
                height: 50%;
                width: 60%;
                .title-text {
                    font-size: 1.25rem;
                    width: 70%;
                }
            }
            .detail-techs {
                position: absolute;
                bottom: 15%;
                width: 88%;
                .tech-icon {
                    width: 20%;
                    height: auto;
                }
            }
            .detail-blob {
                width: 100%;
                height: auto;
                bottom: 0%
            }
        } 
        .blurb-bubbleWrap {
            margin-top: -50vh;
            max-width: 50%;
            overflow: hidden;
        }
    }
  }
  @media only screen and (min-width: 42rem) and (max-width: 1400px) {
    .about {
        
        .about-detail {
            .detail-title{
                width: 70%;
                .title-text {
                    font-size: 1.25rem;
                    width: 70%;
                }
            }
            .detail-techs {
                position: absolute;
                bottom: 12%;
                width: 88%;
                .tech-icon {
                    width: 20%;
                    height: auto;
                }
            }
            .detail-blob {
                bottom: 0%
            }
        } 
        .blurb-bubbleWrap {
            max-width: 50%;
            overflow: hidden;
        }
    }
  }
  