.contact {
  position: relative;
  margin-top: 16vh;
  margin-bottom: 4vh;
  height: 83px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.contact .contact-title {
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
}
.contact a {
  height: 32px;
  padding: 8px;
  z-index: 1105;
}
.contact a .contact-icon {
  height: 100%;
  width: auto;
  z-index: 140;
}

