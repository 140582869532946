.button-row {
  padding: 8px;
  display: flex;
  margin: 0 4px 0 4px;
  position: sticky;
  bottom: 20px;
  left: 20px;
  opacity: 0.7;
  z-index: 1100;
}
.button-row .toggle-button {
  height: 0;
  width: 0;
  visibility: hidden;
}
.button-row .toggle-label {
  width: 54px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  background: #2a2b2a27;
  transition: background-color 0.8s;
}
.button-row .toggle-label .toggle-switch {
  height: calc(0.75 * 32px);
  width: calc(0.75 * 32px);
  left: 4px;
  bottom: 4px;
  border-radius: 36px;
  box-shadow: 0 0 8px 0 rgba(10, 10, 10, 0.45);
  position: absolute;
  transition: 0.8s;
  background: white;
}
.button-row .toggle-button:checked + .toggle-label .toggle-switch {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}
.button-row .toggle-button:checked + .toggle-label {
  background-color: #00c3e680;
}
.button-row .toggle-label:active .toggle-switch {
  width: 54px;
}
.button-row .dm-image {
  height: calc(0.45 * 32px);
  width: auto;
  margin-left: calc(0.25 * 32px);
  margin-top: calc(0.275 * 32px);
}

@media only screen and (min-width: 0rem) and (max-width: 42rem) {
  .button-row {
    position: absolute;
    top: 7px;
    left: 14px;
  }
  .button-row .toggle-label {
    width: 45px;
    height: 27px;
  }
  .button-row .toggle-label .toggle-switch {
    height: calc(0.75 * 27px);
    width: calc(0.75 * 27px);
  }
  .button-row .toggle-label:active .toggle-switch {
    width: 45px;
  }
  .button-row .dm-image {
    height: calc(0.5 * 27px);
    width: auto;
    margin-left: calc(0.25 * 27px);
    margin-top: calc(0.25 * 27px);
  }
}

