.home-container {
  position: relative;
  height: 86vh;// change back to 100% later
  width: 88%; // change back to 100% later
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: solid 1.2px black;
  .glasses-container { 
    text-align: center;
    width: 602px;
    max-width: 100vw;
    height: 324px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 505;
    .glasses-icon {
        position: absolute;
    }
  }
  .svg-waveLine {
    position: absolute;
    opacity: .5;
    filter: blur(42px);
    transform: scale(2);
  }
  
  .home-col {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    height: 90%;
    align-items: center;
    align-content: center;
    justify-content: center;
    // border: solid 0.8px black;

    .home-text{
      width: 100%;
      .letter { 
        display: inline-flex;
      }
    }
    .home-text-title {
      font-size: 3.75rem; // 60px
      .letter {
        margin-right: 0.05rem;
      }
    }
    .home-text-intro {
      font-size: 2rem; // 32px
      font-family: 'Source Sans Pro';
      margin-left: 8%;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      .highlightBox { 
        content: "";
        position: absolute;
        height: 1.875rem;
        width: 32%;
        margin-top: 0.75rem;
        background-image: linear-gradient(-120deg, #e0afa01d, #b4d5fe92 95%, #88d4984f);
        border-radius: 1rem 0 1rem 0;
        padding: 3px 6px;
        z-index: 0;
      }
    }
    .home-text-intro > * {
      z-index: 100;
    }
    .home-text-passage {
      font-size: 1rem; // 16px
      font-family: 'Vazirmatn';
      width: 65%;
      margin-left: 15%;
    }
  }
  .home-profile {
    height: 70%;
    .svg-circle {
      position: absolute;
    }
    .profile-img {
      position: absolute;
      opacity: 0.75;
      z-index: 500;
    }
    .svg-disk {
      position: absolute;
      bottom: 0;
    }
  }

  .bg {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 40%;
    z-index: 508;
  }
}
.home-container.dark {
  border: solid 1.2px white;
}


// 43rem, 62rem, 
@media only screen and (min-width: 0rem) and (max-width: 42rem) {
  .home-container {
    margin-top: 4vh;
    height: 80vh;
    overflow: hidden; // for glasses container causing horizontal scroll during animation
    .svg-waveLine {
      display: none;
    }
    .home-col {
      width: 100%;
      height: 40vh;
      .profile-img {
        width: 50%;
      }
      .svg-circle {
        opacity: 0.5;
        width: 50%;
      }
      .svg-disk {
        padding-bottom: 2%;
        width: 50%;
      }
    }
    .home-text-col {
      align-content: flex-start;
      text-align: center;
      padding-top: 2vh;
      .home-text-title {
        font-size: 2rem; // 42px
      }
      .home-text-intro {
        font-size: 1rem; // 24px
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        margin-left: 0;
        .highlightBox { 
          height: 1rem;
          width: 32%;
          margin-top: 0.25rem;
        }
      }
      .home-text-passage {
        margin-left: 0;
        font-size: 0.75rem;
      }
    }
  }
}
