* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  width: 100vw;
  background-color: #2A2B2A;
  overflow-x: hidden;
}
body #root {
  width: 100vw;
  position: relative;
}
body #root .main {
  background-color: #FCF7FF;
  width: 100%;
}
body #root .main .wave {
  position: absolute;
}
body #root .darkMain {
  background-color: #2A2B2A;
}

.navbar {
  font-family: "Source Sans Pro", "Vazirmatn", sans-serif;
  font-size: 1rem;
  list-style-type: none;
  background-color: #FCF7FF;
  border-bottom: solid 1px #2A2B2A;
  overflow: hidden;
  position: fixed;
  position: -webkit-fixed;
  z-index: 1001;
  margin: 0;
  top: 0;
  width: 100vw;
  height: 7vh;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: right;
}
.navbar li a {
  padding-right: 1.75rem;
  position: relative;
}
.navbar li a::after {
  content: "";
  position: absolute;
  top: 115%;
  height: 1.5px;
  width: 0%;
  left: 5%;
  background-color: #2A2B2A;
  opacity: 0.5;
  transition: all 0.4s ease-in-out;
}
.navbar li:hover {
  cursor: pointer;
}
.navbar li:hover a::after {
  width: 85%;
  transition: 0.4s ease-in-out;
}
.navbar .li-home {
  position: absolute;
  left: 1.5rem;
  font-size: 1.125rem;
}
.navbar .li-home a::before {
  content: "";
  position: absolute;
  height: 0.75rem;
  width: 85%;
  margin-top: 0.5rem;
  background-image: linear-gradient(-120deg, #e0afa01d, #b4d5fe92 95%, #88d4984f);
  border-radius: 1rem 0 1rem 0;
  padding: 3px 6px;
  z-index: 100;
}

.app {
  position: relative;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  padding-top: 7vh;
  justify-content: center;
  background-color: #2a2b2a00;
  font-family: "Lora", "Source Sans Pro", "Bebas Neue", "Plus Jakarta Sans", "Vazirmatn", sans-serif;
  overflow-x: hidden;
}
.app .dark {
  background-color: #2a2b2a00;
}
.app .page {
  position: relative;
  width: 88%;
  display: flex;
}

.dark {
  color: #FCF7FF;
}

.navbar.dark {
  border-bottom: solid 1px #FCF7FF;
  background-color: #2A2B2A;
}
.navbar.dark a::after {
  background-color: #FCF7FF;
}

.grain {
  display: none;
}

.main::before,
.main::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
}

.main::before {
  background: #8090BC;
  filter: url(#grainFilter);
}

.main.darkMain::before {
  background: #2E6864;
}

@media only screen and (min-width: 0rem) and (max-width: 42rem) {
  .burger-svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 4vw;
    align-self: center;
    z-index: 1105;
  }

  .navbar-mobile {
    font-family: "Source Sans Pro", "Vazirmatn", sans-serif;
    font-size: 1rem;
    list-style-type: none;
    border-bottom: 1px solid black;
    position: fixed;
    position: -webkit-fixed;
    height: 24vh;
    margin: 0;
    top: 0;
    padding-top: 7vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 1004;
    background-color: #FCF7FF;
  }
  .navbar-mobile li {
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1010;
  }
  .navbar-mobile .background-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1002;
  }
  .navbar-mobile .background-svg .background-circle {
    margin-left: 20%;
  }

  .navbar-mobile.dark {
    border-bottom: 1px solid white;
    background-color: #2A2B2A;
  }
}

