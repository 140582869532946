.contact {
    position: relative;
    margin-top: 16vh;
    margin-bottom: 4vh;
    height: 83px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .contact-title {
        font-size: 1rem; // 35px with bottom padding
        font-family: 'Source Sans Pro', sans-serif;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 4px;
    }
    a {
        height: 32px; // 48px total height
        padding: 8px;
        z-index: 1105; // body->navbar->toggle->contacts
        .contact-icon {
            height: 100%;
            width: auto;
            z-index: 140;
        }
    }
}