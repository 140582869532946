.projects {
    height: 250vh;
    height: 2700px; // 2490 for 5 cards
    margin-top: 16vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family:  'Source Sans Pro', sans-serif;

    // Main Title
    .projects-main-title {
        font-family: 'Lora';
        font-size: 1.25rem;
        align-self: flex-start;
        position: block;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 2.5%;
        border: 1px solid black;
        .title-highlight::before {
            content: "";
            position: absolute;
            height: 1.25rem;
            width: 4.5rem;
            margin-top: 0.875rem;
            background-color: rgba(0,0,0,0.15);
            border-radius: 0.75rem;
            z-index: 0;
        }
        .first::before{
            width: 6.25rem;
        }
        .dark::before {
            content: "";
            position: absolute;
            height: 1.25rem;
            width: 4.5rem;
            margin-top: 0.875rem;
            background-color: rgba(250,250,250,0.15);
            border-radius: 0.75rem;
            z-index: 0;
        }
        .dark.first::before{
            width: 6.25rem;
        }
    }
    .projects-main-title.dark{
        border: 1px solid white;
    }
    // Project Cards
    .project-card {
        width: 70%;
        height: 450px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: end;

        // border: 1px solid white;
        // Card Image Section
        .card-image-wrap {
            // border: 1px solid white;
            position: relative;
            display: flex;
            align-items: center;
            width: 46%;
            height: 100%;
            z-index: 2;
            .card-image {
                position: absolute;
                width: 100%;
            }
            .blob {
                height: 100%;
                width: auto;
                filter: blur(8px);
            }
            .odd-blob {
                right: 0;
                margin-right: -20%;
            }
            .even-blob {
                left: 0;
                margin-left: -15%;
            }
            .card-link {
                height: auto;
                width: 60%;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                .example {
                    width: 100%;
                }
            }
        }
        // Card Text Section
        .card-text {
            position: relative;
            width: 54%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            z-index: 2;
            .card-text-title {
                font-size: 1.25rem;
                padding: 2.5%;
                width: 100%;
                text-decoration: none;
                // color: inherit;
                color: #39A450;
                font-weight:400;

            }
            .card-text-title.dark {
                color: #4390F9;

            }
            .card-text-text {
                font-weight:300;
                font-size: 1rem;
                padding: 2.5%;
            }
            .card-text-tech {
                font-size: 0.875rem;
                padding: 1.25% 2.5% 1.25% 2.5%;
                opacity: 0.7;
                width: 100%;
            }
            .card-text-tech.dark {
                opacity: 0.5;
            }
            .odd-card {
                text-align: end;
            }
        }
    }
}


@media only screen and (min-width: 0rem) and (max-width: 42rem) {
    .projects {
        height: 350vh; // changes this for sep distance
        margin-top: 8vh;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // Main Title
        .projects-main-title {
            align-self: flex-start;
            position: block;
            display: flex;
            justify-content: center;
        }
        // Project Cards
        .project-card {
            width: 88%;
            height: 20%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 0vh;
            // Card Image Section
            .card-image-wrap {
                // border: 1px solid white;
                position: relative;
                display: flex;
                align-items: end;
                width: 100%;
                height: 50%;
                .card-image {
                    position: absolute;
                    width: 100%;
                    height: auto;
                }
                .blob {
                    height: 100%;
                    width: auto;
                }
                .odd-blob {
                    margin-bottom: -35%;
                    margin-right: 0;
                    rotate: 90deg;
                }
                .card-link {
                    height: auto;
                    width: 72%;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    .example {
                        width: 100%;
                        height: auto;
                        overflow: visible;
                    }
                }
                // .card-link-svg {

                // }
            }
            // Card Text Section
            .card-text {
                width: 100%;
                height: auto;
                align-content: start;
                .card-text-tech {
                    font-size: 0.75rem;
                }
                .odd-card {
                    text-align: start;
                }
            }
        }
    }
    
}